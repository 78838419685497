<template>
  <div class="print">
    <div v-show="false" ref="print">
      <h1>Teste de impressão</h1>
      <div>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
        velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
        occaecat cupidatat non proident, sunt in culpa qui officia deserunt
        mollit anim id est laborum.
      </div>
    </div>
    <v-btn color="primary" outlined elevation="0" class="mb-4" @click="print">
      Imprimir
    </v-btn>
    <!-- <CompleteDialog ref="completeDialog" />
    <div v-if="loading" class="mt-10 loading-container">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </div> -->
  </div>
</template>
<script type="text/javascript">
export default {
  name: 'print',

  methods: {
    print() {
      // Get HTML to print from element
      console.log(this.$refs.print)
      const prtHtml = this.$refs.print.innerHTML

      // Get all stylesheets HTML
      const stylesHtml = `
          <style type="text/css" scoped>
            body {
              font-family: 'Avenir', Helvetica, Arial, sans-serif;
              font-size: 14px;
              color: rgba(0, 0, 0, 0.87);
            }

            p {margin: 0px}

            .logo {
              text-align: center;
              margin-bottom: 1em;
            }

            .logo img{
              max-height: 40px;
              max-width: 100px;
              width: auto !important;
            }

            .container {
              max-width: 600px;
              margin: 0 auto;
              padding: 1em;
            }

            .box {
              border: 1px solid black;
              padding: 5px;
              margin-bottom: 1em;
            }

            .box .title {
              font-weight: bold;
              font-size: 1.2em;
              margin-bottom: 10px;
            }

            .page-title {
              text-align: center;
              font-size: 1.4em;
              font-weight: bold;
              margin-bottom: 1em;
            }

            .page-title .subtitle {
              font-weight: normal;
              font-size: 1em;
            }

            .info {
              margin-bottom: 10px;
            }

            .info .label:after{
              content: '\\00a0';
            }

            .info .content {
              font-weight: bold;
            }

            .branch, .shift {
              margin-bottom: 2px;
            }

            .address, .shift span{
              font-size: 0.8em;
              font-weight: normal;
            }

            .grid {
              display: flex;
            }

            .col {
              flex: 1;
            }
          </style>
        `

      const WinPrint = window.open(
        '',
        '',
        'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0'
      )
      WinPrint.document.write(`<!DOCTYPE html>
        <html>
          <head></head>
          <body>
            ${stylesHtml}
            ${prtHtml}
          </body>
        </html>`)

      WinPrint.document.close()
      WinPrint.focus()
      WinPrint.print()
      WinPrint.close()
    },
    // async fetch() {
    //   try {
    //     this.loading = true
    //     const token = this.$route.params.token
    //     const response = await Api.core.schedules.questionnaires(token)
    //     const schedule = response.data.schedule
    //     if (
    //       schedule.questionario_agendamento?.link_questionario_agendamento &&
    //       schedule.questionario_agendamento?.status_resposta !==
    //         QuestionnaireEnum.status.finished
    //     ) {
    //       window.location.href =
    //         schedule.questionario_agendamento.link_questionario_agendamento
    //       return
    //     }
    //     if (
    //       schedule.questionario_realizacao?.link_questionario_realizacao &&
    //       schedule.questionario_realizacao?.status_resposta !==
    //         QuestionnaireEnum.status.finished
    //     ) {
    //       window.location.href =
    //         schedule.questionario_realizacao.link_questionario_realizacao
    //       return
    //     }
    //     this.$refs.completeDialog.show()
    //   } catch (err) {
    //     if (err.response) {
    //       if (err?.response?.status != 500) {
    //         this.$toast.show(err.response.data.errors.join(','), 'error')
    //       } else {
    //         this.$toast.show('Erro ao buscar dados', 'error')
    //       }
    //     } else {
    //       throw err
    //     }
    //   } finally {
    //     this.loading = false
    //   }
    // },
  },
}
</script>
<style scoped lang="scss">
.questionnaires {
  flex-direction: column;
  padding: 1rem;
  width: 90%;
  max-width: 748px;

  .loading-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
  }
}
</style>
